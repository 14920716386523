<template>
  <div class="public">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'公告'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
    <el-form
      :rules="dataRules"
      label-width="100px"
      :model="form"
      ref="form"
      v-loading="dataListLoading"
    >
      <el-form-item v-if="edit" label="编号" prop>{{form.noticeNumber}}</el-form-item>
      <el-form-item label="标题" prop="noticeTitle">
        <el-input placeholder="请输入标题" v-model.trim="form.noticeTitle" class="wd400"></el-input>
      </el-form-item>
      <el-form-item label="正文内容" prop="noticeContent">
        <!-- 123 -->
        <tiny-mce v-model="form.noticeContent"></tiny-mce>
      </el-form-item>
      <!-- <el-form-item label="排序权重" >
          <el-input placeholder="默认为0，权重值越大，服务排序越靠前" v-model='form.orderWeight' class='wd400'></el-input>
        </el-form-item>
        <el-form-item label="浏览次数" >
          <el-input  v-model='form.viewNum' type='number' class='wd400'  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
      </el-form-item>-->
      <el-form-item label="作者">
        <el-input v-model="form.noticeAuther" class="wd400"></el-input>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="form.publishTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width: 400px;"
        >
          <!-- value-format='yyyy-MM-DD HH:mm:ss' -->
          <!-- format="yyyy 年 MM 月 dd 日" -->
        </el-date-picker>
      </el-form-item>
      <el-form-item class="last-el-form-item">
        <el-button type="primary" @click="submit" v-points>保存</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
import Qs from "qs";
import moment from "moment";
export default {
  name: "publicAdd",
  components: {
    TinyMce,
  },
  data() {
    return {
      edit: false,
      form: {
        noticeTitle: "",
        noticeType: "",
        noticeSummary: "",
        noticeRedirectUrl: "",
        noticeContent: "",
        noticeAuther: "",
        orderWeight: "",
        publishTime: "",
        viewNum: "",
      },
      diseasesList: [],
      typeList: [],
      dataRules: {
        noticeTitle: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        noticeType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        // workEnertyType: [{ required: true, message: "请选择宣教类型", trigger: "change" }],
        noticeContent: [
          { required: true, message: "正文内容不能为空", trigger: "blur" },
        ],
        noticeAuther: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
      },
      dataListLoading: false,
      type: "",
    };
  },
  async mounted() {
    // this.now = new Date()
    // console.log(moment().format("YYYY-MM-DD HH:mm:ss"))
    this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let query = this.$route.query;
    if (query.id) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
    await this.getTypeList();
    console.log(this.$route.query.type);
    this.type = this.$route.query.type;
  },
  methods: {
    back() {
      this.$router.back();
    },
    async init(id) {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/notice/getNotice"),
        method: "post",
        data: { stringParam1: id },
      });
      if (res.status) {
        this.form = res.data;
        this.dataListLoading = false;
      }
    },
    async getTypeList() {
      let params = {
        type: "公告分类",
      };
      // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '公告分类',
		
		},
       
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    async submit() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let form = this.form;
          form.classify = 1;
          form.hospitalId = teamsId;
          form.deptId = enertyId;

          // form.hospitalId = user.deptPid
          // form.deptId = user.deptId
          // let obj = {
          //   workName:form.workName,
          //   workType:form.workType,
          //   workEnertyType:form.workEnertyType,
          //   workContent:form.workContent,
          //   hospitalId:user.deptPid,
          //   deptId:user.deptId,
          // }
          // console.log(obj);
          // if(form.id){
          //   obj.id =form.id
          // }
          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl(
              form.id ? "/notice/update" : "/notice/add"
            ),
            method: "post",
            data:this.form,
          });
          console.log(res);
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$router.push("/notice");
              },
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public {
}
</style>